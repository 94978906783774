





import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import ModalResetStudy from '@/components/organisms/quiz/ModalResetStudy.vue'

@Component({
  components: {
    ModalResetStudy
  }
})
export default class ResetStudy extends Vue {
  @Ref()
  private modalResetRef!: ModalResetStudy
  private mounted(){
    this.modalResetRef.showModal()
  }
}
