

























import { Component, Mixins, Vue } from 'vue-property-decorator'
import HeaderModal from '@/components/atoms/quiz/HeaderModal.vue'
import ModalBorder from '@/components/molecules/quiz/ModalBorder.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import DonutOverview from '@/components/organisms/quiz/DonutOverview.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
import LabelChart from '@/components/atoms/quiz/LabelChart.vue'
import LabelNameBook from '@/components/atoms/quiz/LabelNameBook.vue'

@Component({
  components: {
    HeaderModal,
    ModalBorder,
    DonutOverview,
    ButtonBase,
    LabelChart,
    LabelNameBook
  },
})
export default class ModalResetStudy extends Mixins(ModalBaseMethod, BaseQuiz) {
  private statistics: any = null

  private async getStatistics(){
    const result = await Vue.prototype.$http.httpWithToken.get('/v3/history_quiz/result_statistics/learning_status')
    if(result.data) {
      this.statistics = result.data.data
    }
  }

  private get overview() {
    if (!this.statistics) return {}

    const correctTotal = this.statistics.correct_count
    const incorrectTotal = this.statistics.incorrect_count
    const leftoverTotal = this.statistics.not_drill_count
    const totalQuestion = correctTotal + incorrectTotal + leftoverTotal
    return {
      dataSource: [correctTotal, incorrectTotal, leftoverTotal],
      correctTotal,
      incorrectTotal,
      leftoverTotal,
      percentCorrect: Math.round((correctTotal / totalQuestion) * 100),
      percentIncorrect: Math.round((incorrectTotal / totalQuestion) * 100),
      percentLeftover: Math.round((leftoverTotal / totalQuestion) * 100),
    }
  }

  private back(){
    this.$router.back()
  }

  private goToAchieve() {
    this.$router.replace('/quiz/achievements')
  }

  private async resetStudy() {
    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken.delete('/v3/history_quiz/reset_statistic').then(()=> {
      this.backOut()
    })
    Vue.prototype.$loading.complete()
  }

  public showModal() {
    this.show()
    this.getStatistics()
  }
}
